import {Typography,  Box, IconButton, MenuItem, Menu} from '@mui/material'
import { memo, useState, useEffect, useRef } from 'react';
import QuibenchLogo from '../../assets/svg/QuibenchLogo';
import MiniQuibenchLogo from '../../assets/svg/MiniQuibenchLogo';
import { useNavigate } from 'react-router-dom';
import { ArroRight } from '../../assets/svg/ArrowRight';
import MenuIcon from '@mui/icons-material/Menu';

const headerOption = {
    backgroundColor: 'rgba(225, 217, 243, 1)',
    fontWeight: 700,
    borderRadius: '25px',
    padding: '5px 25px',
    '&:hover': {
        cursor: 'pointer'
    },
    fontFamily: '"Manrope", sans-serif'
}

const font = {
    small: '11px',
    mobile: '11px',
    middle: '12x',
    tablet: '13px',
    large: '14px',
    desktop: '14px'
};
const fonts = '16px';

export const Request = ({ fit }) => {
    return <Box sx={{
        display: 'flex',
        width: fit ? 'fit-content' : '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: {
            desktop: '8px 24px',
            large: '8px 24px',
            tablet: '7px 22px',
            middle: '6px 20px',
            small: '5px 14px',
            mobile: '5px 14px'
        },
        fontWeight: '500',
        backgroundColor: 'rgba(183, 160, 231, 1)',
        borderRadius: '20px', 
        '&:hover': { cursor: 'pointer' },
        color: 'black',
        
    }}>
        <Typography sx={{ fontFamily: '"Manrope", sans-serif', paddingRight: {
            small: '5px',
            mobile: '5px',
            middle: '6px',
            tablet: '7px',
            large: '8px',
            desktop: '8px'
        }, fontWeight: 600,  fontSize: font}}>BOOK A DEMO</Typography>
        <ArroRight />
    </Box>
}

const DefaultHeader = memo(() => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleScrollToForm = () => {
        window.scrollTo({
            top: 4065,
            behavior: 'smooth'
        });
    };

    const [chosenOption, setChosenOption] = useState('product');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getPadding = () => {
        if (windowWidth < 400) {
            return '16px';
        } else if (windowWidth < 800) {
            return '40px';
        } else if (windowWidth < 1200) {
            return '64px';
        } else return '96px';
    };

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            alignItems: 'center',
            maxWidth: '100%',
            backgroundColor: 'rgba(36, 33, 43, 1)',
            pt: '48px',
            paddingLeft: getPadding(),
            paddingRight: getPadding(),
            mb: '-2px',
            position: 'relative',
            justifyContent: 'space-between',
        }}>
            {/* Left-aligned logo */}
            <Box sx={{ '&:hover': { cursor: 'pointer' } }}>
                {windowWidth < 750 ? <MiniQuibenchLogo /> : <QuibenchLogo />}
            </Box>

            {/* Center-aligned navigation box */}
            {windowWidth >= 1000 && (
                <Box sx={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    backgroundColor: 'white',
                    borderRadius: '25px',
                    display: 'flex',
                    flexDirection: 'row', 
                    alignItems: 'center',
                }}>
                    {['product', 'pricing', 'resources'].map(option => (
                        <Typography
                            key={option}
                            onClick={() => setChosenOption(option)}
                            sx={chosenOption === option ? headerOption : { 
                                margin: '0 35px', 
                                '&:hover': { cursor: 'pointer' }, 
                                fontFamily: '"Manrope", sans-serif' 
                            }}
                        >
                            {option.charAt(0).toUpperCase() + option.slice(1)}
                        </Typography>
                    ))}
                </Box>
            )}

            {/* Right-aligned elements */}
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {windowWidth >= 1000 && (
                    <Typography
                        sx={{ 
                            color: 'white', 
                            fontSize: '16px', 
                            fontFamily: '"Manrope", sans-serif', 
                            marginRight: getPadding(), 
                            '&:hover': { cursor: 'pointer' } 
                        }}
                        onClick={() => navigate('/login')}
                    >
                        Sign in
                    </Typography>
                )}

                <Box onClick={handleScrollToForm}>
                    <Request fit={true} />
                </Box>
            </Box>

            {windowWidth < 1000 && (
                <>
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        sx={{ padding: '5px' }}
                    >
                        <MenuIcon sx={{ color: 'white' }} />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        MenuListProps={{ 'aria-labelledby': 'long-button' }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        {['product', 'pricing', 'resources'].map(option => (
                            <MenuItem
                                key={option}
                                selected={chosenOption === option}
                                onClick={() => {
                                    setChosenOption(option);
                                    handleClose();
                                }}
                            >
                                {option.charAt(0).toUpperCase() + option.slice(1)}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            )}
        </Box>
    );
});


export default DefaultHeader;
