import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box, Grid, TextField } from '@mui/material';
import { Request } from '../Header/DefaultHeader';
import { Brain } from '../../assets/svg/Brain';
import { Book } from '../../assets/svg/Book';
import { Person } from '../../assets/svg/Person';
import { Question } from '../../assets/svg/Question';
import { Time } from '../../assets/svg/Time';
import { Rate } from '../../assets/svg/Rate';
import { Team } from '../../assets/svg/Team';
import { Days } from '../../assets/svg/Days';
import { Instagram } from '../../assets/svg/Instagram';
import { LandingAccordion } from './LangingAccordion';
import { Facebook } from '../../assets/svg/Facebook';
import { Linkedin } from '../../assets/svg/Linkedin';
import { BoxContainer } from './BoxContainer';
import { CustomChipComponent } from './CustomChip';
import { useNavigate } from 'react-router-dom';

const initialChips = [
    { name: "Knowledge vault", checked: false },
    { name: "Employee onboarding", checked: false },
    { name: "Communication", checked: false },
    { name: "Team management", checked: false },
    { name: "Sales", checked: false },
    { name: "Interview training", checked: false },
    { name: "HR", checked: false },
    { name: "Hiring", checked: false },
    { name: "Reporting", checked: false }
];

const footerLink = {
    borderRadius: '50%', backgroundColor: 'rgba(225, 217, 243, 1)', width: '40px', height: '40px',    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

const noneInline = {
    small: 'none',
    mobile: 'none',
    middle: 'none',
    tablet: 'inline-block',
    large: 'inline-block',
    desktop: 'inline-block',
}

  const borderRadiusProps = {
    box1: '0 20% 0 20%',
    box2: {
      small: '48.1px 48.1px 0px 48.1px',
      mobile: '48.1px 48.1px 0px 48.1px',
      middle: '64.1px 64.1px 0px 64.1px',
      tablet: '74.8px 74.8px 0px 74.8px',
      large: '85.6px 85.6px 0px 85.6px',
      desktop: '85.6px 85.6px 0px 85.6px'
    },
    box4: '0px 0px 0px 50%',
  };

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const LandingPage = () => {

    const [chips, setChips] = useState(initialChips);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const formRef = useRef(null);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleScrollToForm = () => {
        if (formRef.current){
            formRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const getPadding = () => {
        if (windowWidth < 400){
            return 16
        } else if (windowWidth < 800){
            return 40
        }
        else if (windowWidth < 1200){
            return 64
        }
        else return 96
    };

    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    const navigate = useNavigate();

    return (
        <Box sx={{ maxWidth: '100vw'}}>
            <Box sx={{ display: 'flex', backgroundColor: 'rgba(36, 33, 43, 1)',
            flexDirection: windowWidth > 800 ? 'row' : "column",
         justifyContent: 'space-between',
         alignItems: 'center',
         gap: `${getPadding()}px`,
         pt: 6, pb: 14,
         borderRadius: {
            desktop: '0 0 260px 0',
            large: '0 0 230px 0',
            tablet: '0 0 220px 0',
            middle: '0 0 180px 0',
            mobile: '0 0 100px 0',
            small: '0 0 100px 0',
            paddingLeft: `${getPadding()}px`,
            paddingRight: `${getPadding()}px`,
         }
         }}>
            <Box>
                <Typography sx={{color: 'black', backgroundColor: 'white', borderRadius: '20px', padding: '5px 12px', mb: 3, display: 'inline-block', fontSize: {
                    small: '9px',
                    mobile: '9px',
                    middle: '11px',
                    tablet: '12px',
                    large: '13px',
                    desktop: '14px',
                }, fontFamily: '"Manrope", sans-serif', fontWeight: 600}}>All-in-One Solution for Efficiency and Scalable Growth</Typography>
                <Typography sx={{
                    color: 'white',
                    fontWeight: 600,
                    fontSize: {
                        small: '24px',
                        mobile: '24px',
                        middle: '34px',
                        tablet: '40px',
                        large: '50px',
                        desktop: '60px'
                    }, fontFamily: '"Manrope", sans-serif',
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    textOverflow: 'ellipsis',
                    maxWidth: '60vw',
                }}>
                    <span style={{display: 'block'}}>
                    We're <span style={{color: 'rgba(183, 160, 231, 1)',}}>Smart Solution </span>
                    </span>
                    <span style={{display: 'block'}}>
                    for <span style={{color: 'rgba(183, 160, 231, 1)',}}>Outsourcing  Providers </span>
                    </span>
                </Typography>

            </Box>

                <Box sx={{mt: 3}}>

                    <Box sx={{display: 'flex', flexDirection: 'row', gap: '17px', justifyContent: 'center', pb: '17px'}}>
            <BoxContainer
                sx={{
                    width: { small: '160px', mobile: '160px', middle: '220px', tablet: '220px', large: '250px', desktop: '280px' },
                    height: { small: '65px',
                        mobile: '65px',
                        middle: '98.8px',
                        tablet: '92.9px',
                        large: '96.5px',
                        desktop: '100px' },
                    borderRadius: borderRadiusProps.box1,
                    overflow: 'hidden'
                }}
            >
                <img src="https://s3-alpha-sig.figma.com/img/d849/0f13/8ad082cea89755a5add36565251c54c2?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dYFvyooHLUos~OVPyY9DZEV5H2QcPDGZd7H-Yr-20nVLCjRCtG8RZXxp7OWjzGalpv0kz1UE7AMSTi2ZzLpKW2cYoBP0c-WSWAvONN~QDUxTZobRtrco78GSTKnyJHD9kSlOl4A0Vmvo3rBqZtAavFkRO5v5fdrvif7Jbgn6Dlfz9B2m6F2hzPQ6g3UjrCDF5xBrS2h3Hlaknc~8lVoTWw6Yww2qirCzeIUWBL6mUeXEL-vju~uX3g3EwAba-XhkdaaSX3dKWGo6OhIGW1HeCmSM0UbO2uVJEdVDGZGpfhsbKppvXviPAG1NkMz2OUBvLFhYgOLd~mTfgUKzud-b1g__" alt="Grid Image" style={{ width: '100%', height: '100%', borderRadius: '0 210px 0 210px', objectFit: 'cover' }} />
            </BoxContainer>
            <BoxContainer sx={{ borderRadius: borderRadiusProps.box2, backgroundColor: 'rgba(225, 217, 243, 1)',
            width: {
                small: '75px',
                        mobile: '75px',
                        middle: '98.8px',
                        tablet: '92.9px',
                        large: '96.5px',
                        desktop: '105px'
            }, height: {
                        small: '65px',
                        mobile: '65px',
                        middle: '98.8px',
                        tablet: '92.9px',
                        large: '96.5px',
                        desktop: '100px'
            }
            }} />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '17px', justifyContent: 'center', pb:'17px',}}>
            <BoxContainer
            sx={{
                    borderRadius: borderRadiusProps.box1,
                    overflow: 'hidden',
                    width: { small: '75px',
                        mobile: '75px',
                        middle: '98.8px',
                        tablet: '92.9px',
                        large: '96.5px',
                        desktop: '125px' },
                    height: { small: '65px',
                        mobile: '65px',
                        middle: '98.8px',
                        tablet: '92.9px',
                        large: '96.5px',
                        desktop: '120px' }
                }}
                >
                <img src="https://s3-alpha-sig.figma.com/img/e6bc/dd02/574948f6a272bd29d117c3b4779774e6?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=mctIORLSbIkquMGoxJzGRjyFUZRY04AcgjJjEyiyOqXmCu8dPd3TlCs5~CmFvnVAHJZdhaiGOqWarSPcaaVuDu-4lZgRCIGd9pv~cOFba8FRl1D6gkMcAoeiK0jIm-Gc5~3QUgeFjG~h7TpWufTareByu5FOFAm2PGI0vSKKGG1F7vME83k4JR3FMwqkV38h2uTT5~IRxQIhC1AlwBMs-gxsSgkSmlYdOWNPdv5QZDdtnRjtsPspcYa2Ts97h2gXyw4GWngG27O1whXZmG8OC1yTug-hN4fM11QGgNBt7lxNXR~u~uEPXV0E-ek0szrkhpslRcrKCsDz3-DJmpnlbw__" alt="Grid Image" style={{ width: '100%', height: '100%', borderRadius: '0 50% 50% 50%', objectFit: 'cover' }} />
            </BoxContainer>
            <BoxContainer sx={{ backgroundColor: 'rgba(183, 160, 231, 1)', borderRadius: {
                small: '10px',
                mobile: '10px',
                middle: '15px',
                tablet: '20px',
                large: '20px',
                desktop: '20px'
            },
            width: { small: '65px',
                mobile: '65px',
                middle: '98.8px',
                tablet: '92.9px',
                large: '116.5px',
                desktop: '130px' },
            height: { small: '65px',
                mobile: '65px',
                middle: '98.8px',
                tablet: '92.9px',
                large: '116.5px',
                desktop: '130px' }
            }} />
            <BoxContainer
            sx={{
                    overflow: 'hidden',
                    width: { small: '75px',
                        mobile: '75px',
                        middle: '98.8px',
                        tablet: '92.9px',
                        large: '96.5px',
                        desktop: '120px' },
                    height: { small: '65px',
                        mobile: '65px',
                        middle: '98.8px',
                        tablet: '92.9px',
                        large: '96.5px',
                        desktop: '120px' }
                }}
                >
                <img src="https://s3-alpha-sig.figma.com/img/f86c/aaf8/c21117ffaaf14c6ad9ed759ecc70272c?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dRC3v1NXmy2ky02K6JcqbcrZ3vrLuBJY84oziS6OcQp2LoQuFcZlpKxvi5NdSvf9zSRbV5BCmdgP2CtIahf7o8PKhY5yNvZE1oXq9Amd4f6TlIYLXLOcf-dSEn~wRJnbVgdsxjM5G9ntjtmh00hOIQdor2Ru6RgOPyDHoBVCpniFG25J27aYo99k4bbt5fh35CuGydOrBGznLtlTs2tEWWA16y2ya5baHnd-iAsfurY-jPuDDD22m3fAithIJTS32T~T4JXz1rK9Hm7FvSLzKhkYmaQrUHeHVAfDvKd6ny5MFfhULk8W8FTUSsyrGa9YZCH-hfLFHoPQoXklidKVOw__" alt="Grid Image" style={{ width: '100%', height: '100%', borderRadius: '0px 0px 400px 0px', objectFit: 'cover', WebkitTransform: 'scaleX(-1)'}} />
            </BoxContainer>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '17px', justifyContent: 'center'}}>
            <BoxContainer sx={{ backgroundColor: 'rgba(240, 239, 245, 1)', borderRadius: '50%',  width: {
                small: '65px',
                        mobile: '65px',
                        middle: '98.8px',
                        tablet: '92.9px',
                        large: '96.5px',
                        desktop: '100px'
            }, height: {
                        small: '65px',
                        mobile: '65px',
                        middle: '98.8px',
                        tablet: '92.9px',
                        large: '96.5px',
                        desktop: '100px'
            } }} />
            <BoxContainer
                sx={{
                    width: { small: '160px', mobile: '160px', middle: '220px', tablet: '220px', large: '250px', desktop: '290px' },
                    height: { small: '65px',
                        mobile: '65px',
                        middle: '98.8px',
                        tablet: '92.9px',
                        large: '96.5px',
                        desktop: '100px' },
                    borderRadius: borderRadiusProps.box2,
                    overflow: 'hidden'
                }}
            >
                <img src="https://s3-alpha-sig.figma.com/img/b22d/b4ff/56d30f2bc9d591e18d1f776a3f724ea9?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ClzDEqKUhysGKjACUX7gqT5vsyVBKHfcKCvkYQLeizT7KkSA~fk5LS5q6b1lqxCVppJ90aqA8N5SSqIrgo8xDsKRtUmDNBtN7-49Nsm~DTlMgnj91egVctO~ncvxiSyIxYZm4jda-eoMGaDXQwkCiZYFfcMJNrWGc6OOKw6RhHbyt2iEWHwoBI4KLUUzGDAd1zkdr~n~RYJ4WrmjYZgqlqxXhSXX79HbMi35618vJcFmIOwjCrV~YiRYneU~9pkYZGbtUbTNbv28DORyACcftlyZcmXTteinyc8G61TCwu57AKwLbGwl~Z9VMjIeYulw-2NexmQmgZ1Ex9CgP6JQ3w__" alt="Grid Image" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit' }} />
            </BoxContainer>
        </Box>
        </Box>
</Box>

        <Box sx={{
             display: 'flex', gap: `${getPadding() / 2}px`, flexDirection: {
            small: 'column', mobile: 'column', middle: 'column', tablet: 'row', large: 'row', desktop: 'row'
        }, justifyContent: 'space-between', alignItems: 'center', mt: {
            desktop: '160px',
            large: '120px',
            tablet: '120px',
            middle: '80px',
            small: '60px',
            mobile: '60px'
        }, mb: {
            desktop: '160px',
            large: '120px',
            tablet: '120px',
            middle: '80px',
            small: '60px',
            mobile: '60px'
        }, paddingLeft: `${getPadding()}px`,
        paddingRight: `${getPadding()}px`}}>

        <Box sx={{ display: windowWidth > 800 ? 'inline' : 'none',  borderRadius: '0 25% 25% 25%', flex: 0.75, maxWidth: '60vw'}}>
        <img width="100%"
                    height="100%"
                    style={{
                    objectFit: 'cover',
                    borderRadius: 'inherit',
                }} src="https://s3-alpha-sig.figma.com/img/e95b/428f/d5600abb80728d05633d55355960e5d0?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=YvSEus~taFTe-I0RseAfbWH-zDEJijDZjR5XBP9Fyl4LaF4bAGRk7fOVXmCzbqAvvxC8vCPPEBLdIfKjX6EGqb3mHQvLI4i5JffIr4mjPsrlTARf~dCFyUxPxMwHcysLE0ux1aOFSzTMqehw08-XfxoO8RdjPwZj8vCS~KRzZwQM18W6DFd3V~t4vWj3k2pgV8wAr7sU0Aqhk-OztsnAJ6CLS6FW7lgqsDxXusEnUAE3I3iZCseBPrOovUkan45EIG0z1tr-elcxYmWyiTai4R-8Ss10JiKL5ihs-Qlr5MOCP82KbvDMy9tBPsJQuhqjGKMYHSBwmOHd8RPwEyX~cQ__" alt="picture" />

        </Box>

                <Box sx={{ display : 'flex', flexDirection: 'column', textAlign: 'center', mt: 4, flex: 1.5}}>

                    <Typography sx={{ mb: 2, fontFamily: '"Manrope", sans-serif', fontSize: {
                        small: '13px',
                        mobile: '13px',
                        middle: '15px',
                        tablet: '17px',
                        large: '18px',
                        desktop: '20px',
                    }, maxWidth: '600px', ml: 'auto', mr: 'auto'}}>
                    Quibench is complex solution for effective outsource group. Select one or more key areas for your use:
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', columnGap: '10px' }}>
                    {chips.map((item) => {
                        return (
                            <CustomChipComponent chip={item}></CustomChipComponent>
                        )
                    })}
                    </Box>
                    <Box sx={{ margin: '20px auto'}} onClick={handleScrollToForm}>
                    <Request fit={true} />
                    </Box>
                </Box>
        </Box>

        <Box sx={{
            backgroundColor: 'rgba(227, 219, 244, 1)',
            display: 'flex',
            pt: {
                small: '50px',
                mobile: '50px',
                middle: '50px',
                tablet: '100px',
                large: '100px',
                desktop: '100px'
            }, pb: '150px',
            flexDirection: windowWidth > 1000 ? 'row' : 'column',
            justifyContent: 'space-between',
        borderRadius: '0 0 0 300px',
        paddingLeft: `${getPadding()}px`,
            paddingRight: `${getPadding()}px`,
        }}>

            <Typography sx={{ color: 'rgba(183, 160, 231,1)',
            mr: windowWidth > 1000 ? '85px' : 'auto',
            mb: windowWidth > 1000 ? '0' : '30px',
            writingMode: windowWidth < 1000 ? '' : 'vertical-lr',
            transform: windowWidth < 1000 ? 'rotate(0deg)' : 'rotate(180deg)',
            fontSize: {
                small: '18px',
                mobile: '18px',
                middle: '18px',
                tablet: '16px',
                large: '18px',
                desktop: '22px'
            },
            textAlign: 'end',
            fontFamily: '"Manrope", sans-serif',
            fontWeight: 600
            }}>WHY CHOOSE <span style={{ color: 'black' }}>QUIBENCH</span>?</Typography>

            <Box
                sx={{
                    margin: '0 auto'
                }}
            >
                <Grid container spacing={4}>
                    {[
                        {
                            icon: <Book />,
                            title: "Knowledge Core",
                            description:
                                "A secure hub providing instant access to key knowledge, client insights, and solutions. Quickly find the resources you need to solve challenges, streamline workflows, and boost team productivity.",
                        },
                        {
                            icon: <Person />,
                            title: "Interview & Onboarding Companion",
                            description:
                                "AI-driven tools that streamline interview preparation, automate onboarding tasks, and guide technical specialists for faster adaptation and skill development on projects.",
                        },
                        {
                            icon: <Time />,
                            title: "Recruitment & HR Tools",
                            description:
                                "Automates recruitment workflows, simplifies onboarding, and tracks performance metrics to save time, reduce errors, and enhance the efficiency of HR processes.",
                        },
                        {
                            icon: <Team />,
                            title: "Metrics & Resource Utilization",
                            description:
                                "Provides actionable insights by tracking KPIs such as team performance, resource allocation, and growth trends, helping you refine strategies and achieve better results.",
                        },
                    ].map((item, index) => (
                        <Grid item middle={12} tablet={6} key={index}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'space-between'}}>

                                    <Box sx={{
                                        maxWidth: {
                                            small: '40px',
                                            mobile: '40px',
                                            middle: '45px',
                                            tablet: '45px',
                                            large: '50px',
                                            desktop: '50px'
                                        },
                                        maxHeight: {
                                            small: '40px',
                                            mobile: '40px',
                                            middle: '45px',
                                            tablet: '45px',
                                            large: '50px',
                                            desktop: '50px'
                                        }
                                    }}>
                                        {item.icon}
                                    </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                    <Typography  sx={{ fontFamily: '"Manrope", sans-serif', mb: {
                                        desktop: 2.5,
                                        large: 2.5,
                                        tablet: 2.5,
                                        middle: 2,
                                        mobile: 1.5,
                                        small: 1.5
                                    }, fontWeight: 600, fontSize: {
                                        small: '15px',
                                        mobile: '15px',
                                        middle: '17px',
                                        tablet: '18px',
                                        large: '22px',
                                        desktop: '22px'
                                    }}}>{item.title}</Typography>
                                    <Typography sx={{ fontFamily: '"Manrope", sans-serif', color: 'rgba(106, 103, 113, 1)', fontSize: {
                                        small: '12px',
                                        mobile: '12px',
                                        middle: '14px',
                                        tablet: '14px',
                                        large: '16px',
                                        desktop: '16px'
                                    }}}>{item.description}</Typography>
                                </Box>
                            </Box>

                        </Grid>
                    ))}
                </Grid>

            </Box>
        </Box>

        <Box sx={{ display: 'flex',
        flexDirection: windowWidth < 800 ? 'column' : 'row',
        justifyContent: 'space-between',
        gap: '30px',
         alignItems: 'center', marginTop: {
            small: '80px',
            mobile: '80px',
            middle: '120px',
            tablet: '120px',
            large: '160px',
            desktop: '160px'
        }, marginBottom: {
            small: '80px',
            mobile: '80px',
            middle: '120px',
            tablet: '120px',
            large: '160px',
            desktop: '160px'
        },
        paddingLeft: `${getPadding()}px`,
        paddingRight: `${getPadding()}px`, }}>

            <Box sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexGrow: 0.75,
                alignSelf: windowWidth > 1200 ? 'center' : 'flex-start'
            }}>
                <Typography sx={{ fontSize: {
                    small: '22px',
                    mobile: '22px',
                    middle: '28px',
                    tablet: '36px',
                    large: '40px',
                    desktop: '48px'
                }, fontFamily: '"Manrope", sans-serif', fontWeight: 600}}>Focus on <span style={{
                    color: 'rgba(183, 160, 231, 1)'
                }}>result</span></Typography>

                <Typography sx={{ mb: 6, mt: 2, fontSize: {
                    small: '14px',
                    mobile: '14px',
                    middle: '14px',
                    tablet: '16px',
                    large: '18px',
                    desktop: '20px'
                }, fontFamily: '"Manrope", sans-serif', maxWidth: {
                    desktop: '395px',
                    large: '395px',
                    middle: '300px',
                    tablet: '395px',
                    mobile: '240px'
                } }}>
                    Quibench is complex solution for effective outsource group.
                </Typography>

                <Box onClick={handleScrollToForm}>
                <Request fit={true} />
                </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px',  flexGrow: 1.5 }}>
            <Box sx={{ alignSelf: windowWidth > 1200 ? 'flex-end' : 'flex-start', width: {
                small: '270px',
                mobile: '270px',
                middle: '350px',
                tablet: '380px',
                large: '420px',
                desktop: '520px'
            },
            height: {
                small: '158px',
                mobile: '158px',
                middle: '202px',
                tablet: '220px',
                large: '242px',
                desktop: '300px'
            }

            }}>
                <Days />
            </Box>

            <Box sx={{ alignSelf: 'flex-start',
            width: {
                small: '270px',
                mobile: '270px',
                middle: '350px',
                tablet: '380px',
                large: '430px',
                desktop: '520px'
            },
            height: {
                small: '158px',
                mobile: '158px',
                middle: '202px',
                tablet: '220px',
                large: '242px',
                desktop: '300px'
            }
            }}>
                <Rate />
            </Box>
            </Box>

        </Box>

        <Box sx={{
            paddingTop: {
                desktop :'120px',
                large: '120px',
                tablet: '80px',
                middle: '80px',
                small: '60px',
                mobile: '60px'
            },
            paddingBottom: {
                desktop :'120px',
                large: '120px',
                tablet: '80px',
                middle: '80px',
                small: '80px',
                mobile: '80px'
            },
            display: 'flex',
            flexDirection: windowWidth > 800 ? 'row' : 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            backgroundColor: 'rgba(36, 33, 43, 1)',
            borderBottomLeftRadius: {
                small: '145px 70px',
                mobile: '145px 70px',
                middle: '185px 90px',
                tablet: '185px 90px',
                large: '25%',
                desktop: '25%'
            },
            paddingLeft: `${getPadding()}px`,
            paddingRight: `${getPadding()}px`,
            gap: '30px'
        }}>
            <Typography sx={{
                mb: 3,
                fontFamily: '"Manrope", sans-serif',
                fontSize: {
                    small: '23px',
                    mobile: '23px',
                    middle: '27px',
                    tablet: '34px',
                    large: '36px',
                    desktop: '40px'
                },
                color: 'white',
            }}>FA<span style={{
                color: 'rgba(183, 160, 231, 1)'
            }}>Q</span></Typography>


            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alingnItems: "center",
                maxWidth: windowWidth > 800 ? '60%' : '100%',
                mt: '-20px'
            }}>

                    <Box sx={{ display: 'none', height: '0px'}} />
                    {[
                        {question: 'What does it cost?', answer: 'Enjoy exclusive access for free until the official launch! To join, simply get in touch with us today'},
                        {question: 'What should I expect during demo call?', answer: 'In your demo call, our team will guide you through Quibench\'s key features, focusing specifically on your organization\'s needs. We\'ll address your questions and explore how Quibench can support you in achieving extraordinary results.'},
                        {question: 'How secure will my data be?', answer: 'At Quibench, data security is our top priority. We\'ve implemented strong security measures, policies, and procedures to meet required data security standards. We\'re always working to improve our information security. Here are some key measures we use:\n' +
                                '    - Regular vulnerability scans\n' +
                                '    - Web application firewall\n' +
                                '    - Input data validation\n' +
                                '    - Ongoing security management and monitoring\n' +
                                '    - Daily backups\n' +
                                '    \n' +
                                '    Our data center is located in Germany, specifically in Frankfurt.'}
                    ]
                        //     TODO VLAD MAKE IT PLS LIKE A numeric list, but with points
                    .map((item, index) => {
                        return (
                            <LandingAccordion answer={item.answer} id={index} question={item.question}></LandingAccordion>
                        )
                    })}
                    <Box sx={{ display: 'none'}} />
            </Box>
        </Box>

        <Box sx={{
    maxWidth: '100%',
    display: 'flex',
    flexDirection: windowWidth > 800 ? 'row' : 'column',
    justifyContent: 'space-between',
    mt: 10,
    mb: 8,
    paddingLeft: `${getPadding()}px`,
    paddingRight: `${getPadding()}px`,
}}>

    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: windowWidth > 800 ? '35%' : `50%`,
        mb: 4
    }} ref={formRef}>

        <Typography sx={{
            fontFamily: '"Manrope", sans-serif',
            fontWeight: 600,
            fontSize: {
                small: '22px',
                mobile: '22px',
                middle: '28px',
                tablet: '34px',
                large: '40px',
                desktop: '48px'
            }
        }}>
            Book a demo
        </Typography>

        <TextField
            variant="standard"
            color='rgba(183, 160, 231, 1)'
            label="Name"
            sx={{ mt: 2, width: '100%', fontFamily: '"Manrope", sans-serif'}}
            onChange={(e) => setName(e.target.value)}
        />
        <TextField
            variant="standard"
            label="Phone"
            sx={{ mt: 2, width: '100%', fontFamily: '"Manrope", sans-serif'}}
            onChange={(e) => setPhone(e.target.value)}
        />
        <TextField
            variant="standard"
            label="Email"
            sx={{ mt: 2, width: "100%", fontFamily: '"Manrope", sans-serif'}}
            onChange={(e) => setEmail(e.target.value)}
        />

        <Box sx={{ mt: 3 }} onClick={() => {
            if (email.match(/^[a-zA-Z0-9._%+-]+@[aazA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
                && phone.match(/^(\+?\d{1,3}[-.\s]?)?(\(?\d{1,4}\)?[-.\s]?)?(\d{1,4}[-.\s]?)?\d{1,4}[-.\s]?\d{1,4}$/)
                && name.match(/^[a-zA-Zà-ÿÀ-Ÿ'-]+( [a-zA-Zà-ÿÀ-Ÿ'-]+)*$/)){
                    navigate('')
                }
        }}>
            <Box sx={{ width: '88%'}} onClick={() => {

            }}>
                <Request fit={false} />
            </Box>

        </Box>
    </Box>

    <Box sx={{
        display: windowWidth > 800 ? 'block' : 'none',
        borderRadius: {
            // small: '140px 140px 0 140px',
            // mobile: '140px 140px 0 140px',
            // middle: '160px 160px 0 160px',
            tablet: '160px 160px 0 160px',
            large: '190px 190px 0 190px',
            desktop: '220px 220px 0 220px'
        },
        maxHeight: {
            // small: '190px', mobile: '190px', middle: '220px',
            tablet: '220px', large: '250px', desktop: '350px'
        },
        overflow: 'hidden',
        objectFit: 'cover',
        width: windowWidth > 800 ? '55%' : `100%`,
    }}>
        <img
            src="https://s3-alpha-sig.figma.com/img/8724/dc4e/5310b9d8905acce6334aeaa9760c8cca?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=qRIIJ9sBQEd93i9BFjHc5bKPdTZw9Z365iqYqvDcn2jG8vGz58zlhY8HZZ7nJAXjbF27aSBbDTUwnFmjYQ6QEmaWE0VRYT99ZJKS1PW1mbXQ2psjdd56wAwBFzGvGfrUxjNKWhN4TeVJZmo6i6Sj~mOaYf4DsP5VozqKvMb9YC8nUX~6FfNZw-ywqgITvGJOJJvEiDsCw5jvRzWfyOJlEBc~v156-Sy1DKVXkM8C3JnqbNoeHgT6Vy67GI0olsqq3GMvzeaiTX6aa5TVGexl3nA1UVE47r6U51Cm8o4JE82WqT1kwkfJIGG1nhsXFTjTUSKpYdQgvwD4oSy2pXPRmQ__"
            alt="Image with border radius"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover', objectPosition: 'center center -20px' }}
        />
    </Box>

</Box>

{windowWidth < 501 && (
  <Box sx={{
    backgroundColor: 'rgba(36, 33, 43, 1)',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    pt: 5, pb: 4,
    borderRadius: '90px 0 0 0',
    paddingLeft: `${getPadding()}px`,
    paddingRight: `${getPadding()}px`,
  }}>
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: '140px',
      mb: 3
    }}>
      <Box sx={footerLink}>
        <Instagram />
      </Box>
      <Box sx={footerLink}>
        <Facebook />
      </Box>
      <Box sx={footerLink}>
        <Linkedin />
      </Box>
    </Box>

    <Typography sx={{
      color: 'gray',
      fontSize: '13px',
      fontFamily: '"Manrope", sans-serif',
      mb: 3
    }}>
      Privacy Policy <span style={{ color: 'white' }}>&nbsp; &#x2022; &nbsp;</span> Cookie Policy <span style={{ color: 'white' }}>&nbsp; &#x2022; &nbsp;</span> Contact Us
    </Typography>

    <Typography sx={{
      color: 'white',
      fontSize: '15px',
      fontFamily: '"Manrope", sans-serif',
    }}>
      @2024 QUIBENCH
    </Typography>
  </Box>
)}

{windowWidth < 800 && windowWidth > 500 && (
  <Box sx={{
    backgroundColor: 'rgba(36, 33, 43, 1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    pt: 5, pb: 4,
    borderRadius: '90px 0 0 0',
    paddingLeft: `${getPadding()}px`,
    paddingRight: `${getPadding()}px`,
  }}>
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: '140px',
    }}>
      <Box sx={footerLink}>
        <Instagram />
      </Box>
      <Box sx={footerLink}>
        <Facebook />
      </Box>
      <Box sx={footerLink}>
        <Linkedin />
      </Box>
    </Box>

    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 3 }}>
      <Typography sx={{
        color: 'white',
        fontSize: '15px',
        fontFamily: '"Manrope", sans-serif', mr: 4
      }}>
        @2024 QUIBENCH
      </Typography>

      <Typography sx={{
        color: 'gray',
        fontSize: '13px',
        fontFamily: '"Manrope", sans-serif',
      }}>
        Privacy Policy <span style={{ color: 'white' }}>&nbsp; &#x2022; &nbsp;</span> Cookie Policy <span style={{ color: 'white' }}>&nbsp; &#x2022; &nbsp;</span> Contact Us
      </Typography>
    </Box>
  </Box>
)}

{windowWidth > 800 && (
  <Box sx={{
    backgroundColor: 'rgba(36, 33, 43, 1)',
    height: '50px',
    pt: 5, pb: 4,
    borderRadius: '90px 0 0 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    paddingLeft: `${getPadding()}px`,
    paddingRight: `${getPadding()}px`,
  }}>

    <Typography sx={{
      color: 'white',
      fontSize: '15px',
      fontFamily: '"Manrope", sans-serif',
    }}>
      @2024 QUIBENCH
    </Typography>

    <Box sx={{
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: '140px',
    }}>
      <Box sx={footerLink}>
        <Instagram />
      </Box>
      <Box sx={footerLink}>
        <Facebook />
      </Box>
      <Box sx={footerLink}>
        <Linkedin />
      </Box>
    </Box>

    <Typography sx={{
      color: 'gray',
      fontSize: '13px',
      fontFamily: '"Manrope", sans-serif',
    }}>
      Privacy Policy <span style={{ color: 'white' }}>&nbsp; &#x2022; &nbsp;</span>
      Cookie Policy <span style={{ color: 'white' }}>&nbsp; &#x2022; &nbsp;</span>
      Contact Us
    </Typography>
  </Box>
)}



</Box>
)}

export default LandingPage;