import { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export const LandingAccordion = ({ question, answer, id }) => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = () => {
        setExpanded((prev) => !prev);
    };

    return (
        <Accordion
            key={id}
            square={false}
            disableGutters
            expanded={expanded}
            onClick={handleChange}
            sx={{
                backgroundColor: expanded ? "rgba(240, 239, 245, 1)" : "rgba(227, 219, 244, 1)",
                boxShadow: expanded
                    ? "0 4px 12px rgba(0, 0, 0, 0.05), 0 8px 16px rgba(0, 0, 0, 0.1)"
                    : "0 2px 4px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.08)",
                borderRadius: "30px",
                mt: 3,
                padding: {
                    small: '4px 6px',
                    mobile: '4px 6px',
                    middle: '5px 8px',
                    tablet: '6px 10px',
                    large: '6px 10px',
                    desktop: '6px 10px',
                },
                overflow: 'hidden',
            }}
        >
            <AccordionSummary
                sx={{ overflow: 'hidden' }}
                aria-controls={`panel${id}-content`}
                id={`panel${id}-header`}
                expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
            >
                <Typography noWrap  sx={{fontFamily: '"Manrope", sans-serif', fontWeight: 600, fontSize: {
                    small: '14px',
                    mobile: '14px',
                    middle: '16px',
                    tablet: '18px',
                    large: '20px',
                    desktop: '20px',
                }}}>
                    {question}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ overflow: 'auto', maxHeight: 200 }}>
                <Typography noWrap={false} sx={{ wordWrap: 'break-word', fontFamily: '"Manrope", sans-serif', fontSize: {
                    small: '12px',
                    mobile: '12px',
                    middle: '13px',
                    tablet: '15px',
                    large: '16px',
                    desktop: '16px',
                } }}>
                    {answer}
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
};